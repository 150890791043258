@import '../../../../resources';
.full-zone {
  display: flex;
  position: relative;
  height: 100vh;
  .home_pagex {
    padding: 0 50px;
    padding-bottom: 100px;
    margin-bottom: 50px;
    width: 100%;
    padding-top: 100px;
    height: 100vh;
    overflow-y: auto;

    @include mobile-800px {
      padding: 0 20px;
      padding-top: 100px;
    }
    .home_button {
      margin-bottom: 19px;
      button {
        padding: 12px 23px;
        background: #f1f5fd;
        border: 1px solid #afafaf;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 19px;
        margin-bottom: 20px;
      }
    }
    .home_tag {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 20px;
      align-items: center;
      h1 {
        font-size: 28px;
      }
      .home_select {
        @include mobile-600px {
          width: 100%;
          padding-top: 10px;
          text-align: right;
        }
        select {
          background: #ffffff;
          border: 0.5px solid #dedede;
          border-radius: 6px;
          padding: 10px 20px;
          margin-right: 20px;
          cursor: pointer;
        }
      }
      p {
        padding: 0 25px 0 15px;
      }
      input {
        margin-right: 10px;
        padding: 5px;
        height: 100%;
      }
      button {
        background: #446ce4;
        border-radius: 4px;
        border: 1px solid #446ce4;
        font-size: 20px;
        padding: 7px 20px;
        color: #fff;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
    .home_card {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      flex-wrap: wrap;
      .cardx {
        display: flex;
        align-items: center;
        width: 32%;
        background-color: #fff;
        // padding: 25px;
        border-radius: 10px;
        margin-bottom: 10px;
        @include mobile-800px {
          width: 49%;
        }
        @include mobile-600px {
          width: 100%;
        }
        .left {
          margin-right: 13px;
          margin-left: 25px;
          .round {
            width: 40px;
            height: 40px;
            background-color: #ffe5c6;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              font-size: 20px;
              color: #b37027;
            }
          }
          &.dif {
            .round {
              background-color: #dbffc6;
              svg {
                color: #7aa360;
              }
            }
          }
        }
        .right {
          padding: 25px 25px 25px 0;
          p {
            letter-spacing: 0.02em;
            font-size: 14px;
            padding-bottom: 5px;
          }
          h2 {
            font-size: 24px;
            letter-spacing: 0.02em;
            word-break: break-all;
          }
        }
      }
    }
    .home_tables {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 20px;
      .buttons {
        padding-bottom: 30px;
        button {
          background: none;
          border-radius: 4px;
          border: 1px solid #446ce4;
          font-weight: 600;
          font-size: 20px;
          padding: 10px 25px;
          color: #1c1c1c;
          &:nth-child(2) {
            margin-left: 20px;
          }
          &.active {
            background: #446ce4;
            border: none;
          }
        }
      }
      .left {
        width: 100%;
        background-color: #fff;
        border-radius: 10px;
        padding: 25px 30px;
        overflow-x: auto;
        min-height: 40vh;
        @include mobile-800px {
          width: 100%;
          margin-bottom: 30px;
        }
        .left_top {
          padding-bottom: 20px;
          h2 {
            font-size: 20px;
          }
        }
        .swutc {
          display: flex;
          justify-content: center;
          padding: 20px 0;
          .vut {
            width: 100%;
            max-width: 500px;
            border: 1px solid #222;
            display: flex;
            border-radius: 10px;
            overflow: hidden;
            justify-content: space-between;

            .tabx {
              width: 33.3%;
              text-align: center;
              padding: 10px 0;
              cursor: pointer;
              &.activex {
                background-color: #222;
                color: #fff;
              }
            }
          }
        }
        table {
          width: 100%;
          thead {
            tr {
              th {
                font-size: 16px;
                padding: 20px 10px;
                text-align: left;
              }
            }
          }
          tbody {
            tr {
              &:nth-child(odd) {
                background-color: #f5f9ff;
              }
              td {
                font-size: 14px;
                padding: 10px 10px;
                text-transform: capitalize;
                &.icons {
                  max-width: 50px;
                  svg {
                    font-size: 20px;
                    margin-right: 10px;
                    cursor: pointer;
                    &.put {
                      color: #446ce4;
                    }
                    &.del {
                      color: red;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .zone-create {
    position: absolute;
    padding-top: 100px;
    z-index: 1;
    background-color: #fff;
    right: -100%;
    width: 100%;
    max-width: 400px;
    height: 100vh;
    padding: 100px 30px 50px 30px;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.068);
    overflow-y: auto;
    &.activex {
      right: 0;
    }
    .close {
      svg {
        color: red;
        cursor: pointer;
      }
    }
    h1 {
      font-size: 22px;
      padding-bottom: 35px;
    }
    .form {
      .cards {
        padding-bottom: 20px;
        p {
          font-size: 18px;
          padding-bottom: 10px;
        }
        select,
        input {
          width: 100%;
          height: 50px;
          border: 1px solid #afafaf;
          font-size: 16px;
          color: #222;
          padding: 0 5px;
        }
        button {
          background: #446ce4;
          border-radius: 4px;
          border: 1px solid #446ce4;
          font-size: 20px;
          padding: 7px 20px;
          color: #fff;
          width: 100%;
          &.delete {
            background-color: red;
            border: 1px solid red;
          }
        }
      }
    }
  }
}
