.authWrap {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.authWrap .authWrapCol1 {
  width: 50%;
  img {
    width: 100%;
    height: 100%;
  }
}

.authWrap .authWrapCol2 {
  // width: 50%;
  padding: 30px 60px;
  height: 100%;
  overflow-y: scroll;
  .actionAuthBtn {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    button {
      padding: 10px 20px;
      margin-left: 10px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }

    button:first-child {
      background: #000000;
      color: white;
    }

    button:last-child {
      background: transparent;
      color: #000000;
      border: 0.8px solid #000000;
    }
  }
  &.diff {
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 30px 20px;
    }
  }
}

.authHeader {
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-top: 80px;
  text-align: start;
  h2 {
    font-weight: 800;
    font-size: 36px;
    color: #4169e2;
  }
  p {
    // text-align: center;
    font-size: 16px;
    color: #3e3e3e;
    // margin-top: 10px;
  }
}

.formWrap {
  width: 100%;
  // height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding-top: 300px;
  // padding-bottom: 40px;
  form {
    width: 80%;
    // height: 100%;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    .inputWrap {
      width: 100%;
      // height: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    input {
      width: 100%;
      border: 1px solid #929292;
      background-color: transparent;
      height: 50px;
      border-radius: 1px;
      padding-left: 5px;
      outline: none;
      color: #000000;
    }
    label {
      font-size: 16px;
      color: rgba(36, 66, 46, 0.75);
      font-weight: 600;
      margin-bottom: 7px;
    }

    .submitBtn {
      width: 100%;
      height: 45px;
      button {
        background: #000000;
        height: 100%;
        width: 100%;
        border: none;
        color: white;
      }
    }

    .termsAgree {
      p {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.2px;
        color: #323232;
        a {
          color: #4169e2;
        }
      }
    }
  }

  .inputWrapWith2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .inputWrapWith2Col {
      width: 48% !important;
      input {
        width: 100%;
      }
    }
  }
}
