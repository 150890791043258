.dashboardHeader {
  margin-top: 20px;
  h2 {
    font-weight: 800;
    font-size: 34px;
    color: #4169e2;
  }
}
.dashbordCard1 {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  .card {
    width: 100%;
    height: 267px;
    // background: #f1f1f1;
    border-radius: 5px;
    // padding: 30px 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: 20px 20px !important;
    img {
      width: 55px;
    }
    h2 {
      font-weight: 800;
      font-size: 20px;
      color: #fff;
      margin-top: 7px;
    }
    p {
      margin-top: 12px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.2px;
      color: #fff;
    }

    svg {
      margin-top: auto;
    }
  }
}

.dashboardCard2 {
  width: 100%;
  height: 143px;
  background: #f1f1f1;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 60px 30px;
  cursor: pointer;
  img {
    width: 55px;
  }
  h2 {
    font-weight: 800;
    font-size: 20px;
    color: #fff;
    margin-top: 7px;
    margin-right: 40px;
    margin-left: 30px;
  }
  p {
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #323232;
  }
  svg {
    margin-left: auto;
  }
}
