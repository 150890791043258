@import url("https://fonts.googleapis.com/css?family=Jaldi:400,700");
*,
*::after,
*::before {
  box-sizing: inherit;
}



.cd-accordion{
    width: 600px;
}
.cd-accordion__sub {
   padding-left: 0px;
   color: #fff;

}
span{
  text-align: left;
}
:root {
  --space-unit: 1em;
  --space-xxxxs: calc(0.125 * var(--space-unit));
  --space-xxxs: calc(0.25 * var(--space-unit));
  --space-xxs: calc(0.375 * var(--space-unit));
  --space-xs: calc(0.5 * var(--space-unit));
  --space-sm: calc(0.75 * var(--space-unit));
  --space-md: calc(1.25 * var(--space-unit));
  --space-lg: calc(2 * var(--space-unit));
  --space-xl: calc(3.25 * var(--space-unit));
  --space-xxl: calc(5.25 * var(--space-unit));
  --space-xxxl: calc(8.5 * var(--space-unit));
  --space-xxxxl: calc(13.75 * var(--space-unit));
  --component-padding: var(--space-md);
}
:root {
  --max-width-xxs: 32rem;
  --max-width-xs: 38rem;
  --max-width-sm: 48rem;
  --max-width-md: 64rem;
  --max-width-lg: 80rem;
  --max-width-xl: 90rem;
  --max-width-xxl: 120rem;
}
.container {
  width: calc(100% - 1.25em);
  width: calc(100% - 2 * var(--component-padding));
  margin-left: auto;
  margin-right: auto;
  max-width: 86%;
}





.col-11 {
  -ms-flex-preferred-size: calc(91.66% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(91.66% - 0.01px - 1em);
  flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(91.66% - 0.01px - 1em);
  max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
}
.col-12 {
  -ms-flex-preferred-size: calc(100% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(100% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(100% - 0.01px - 1em);
  flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(100% - 0.01px - 1em);
  max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
}



.margin-sm {
  margin: 0.75em;
  margin: var(--space-sm);
}
.margin-md {
  margin: 1.25em;
  margin: var(--space-md);
}
.margin-lg {
  margin: 2em;
  margin: var(--space-lg);
}
.margin-xl {
  margin: 3.25em;
  margin: var(--space-xl);
}
.margin-xxl {
  margin: 5.25em;
  margin: var(--space-xxl);
}
.margin-xxxl {
  margin: 8.5em;
  margin: var(--space-xxxl);
}
.margin-xxxxl {
  margin: 13.75em;
  margin: var(--space-xxxxl);
}
.margin-auto {
  margin: auto;
}
.margin-top-xxxxs {
  margin-top: 0.125em;
  margin-top: var(--space-xxxxs);
}
.margin-top-xxxs {
  margin-top: 0.25em;
  margin-top: var(--space-xxxs);
}
.margin-top-xxs {
  margin-top: 0.375em;
  margin-top: var(--space-xxs);
}
.margin-top-xs {
  margin-top: 0.5em;
  margin-top: var(--space-xs);
}
.margin-top-sm {
  margin-top: 0.75em;
  margin-top: var(--space-sm);
}
.margin-top-md {
  margin-top: 1.25em;
  margin-top: var(--space-md);
}
.margin-top-lg {
  margin-top: 2em;
  margin-top: var(--space-lg);
}
.margin-top-xl {
  margin-top: 3.25em;
  margin-top: var(--space-xl);
}
.margin-top-xxl {
  margin-top: 5.25em;
  margin-top: var(--space-xxl);
}
.margin-top-xxxl {
  margin-top: 8.5em;
  margin-top: var(--space-xxxl);
}
.margin-top-xxxxl {
  margin-top: 13.75em;
  margin-top: var(--space-xxxxl);
}
.margin-top-auto {
  margin-top: auto;
}
.margin-bottom-xxxxs {
  margin-bottom: 0.125em;
  margin-bottom: var(--space-xxxxs);
}
.margin-bottom-xxxs {
  margin-bottom: 0.25em;
  margin-bottom: var(--space-xxxs);
}
.margin-bottom-xxs {
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.margin-bottom-xs {
  margin-bottom: 0.5em;
  margin-bottom: var(--space-xs);
}
.margin-bottom-sm {
  margin-bottom: 0.75em;
  margin-bottom: var(--space-sm);
}
.margin-bottom-md {
  margin-bottom: 1.25em;
  margin-bottom: var(--space-md);
}
.margin-bottom-lg {
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
}
.margin-bottom-xl {
  margin-bottom: 3.25em;
  margin-bottom: var(--space-xl);
}
.margin-bottom-xxl {
  margin-bottom: 5.25em;
  margin-bottom: var(--space-xxl);
}
.margin-bottom-xxxl {
  margin-bottom: 8.5em;
  margin-bottom: var(--space-xxxl);
}
.margin-bottom-xxxxl {
  margin-bottom: 13.75em;
  margin-bottom: var(--space-xxxxl);
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-right-xxxxs {
  margin-right: 0.125em;
  margin-right: var(--space-xxxxs);
}
.margin-right-xxxs {
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.margin-right-xxs {
  margin-right: 0.375em;
  margin-right: var(--space-xxs);
}
.margin-right-xs {
  margin-right: 0.5em;
  margin-right: var(--space-xs);
}
.margin-right-sm {
  margin-right: 0.75em;
  margin-right: var(--space-sm);
}
.margin-right-md {
  margin-right: 1.25em;
  margin-right: var(--space-md);
}
.margin-right-lg {
  margin-right: 2em;
  margin-right: var(--space-lg);
}
.margin-right-xl {
  margin-right: 3.25em;
  margin-right: var(--space-xl);
}
.margin-right-xxl {
  margin-right: 5.25em;
  margin-right: var(--space-xxl);
}
.margin-right-xxxl {
  margin-right: 8.5em;
  margin-right: var(--space-xxxl);
}
.margin-right-xxxxl {
  margin-right: 13.75em;
  margin-right: var(--space-xxxxl);
}
.margin-right-auto {
  margin-right: auto;
}
.margin-left-xxxxs {
  margin-left: 0.125em;
  margin-left: var(--space-xxxxs);
}
.margin-left-xxxs {
  margin-left: 0.25em;
  margin-left: var(--space-xxxs);
}
.margin-left-xxs {
  margin-left: 0.375em;
  margin-left: var(--space-xxs);
}
.margin-left-xs {
  margin-left: 0.5em;
  margin-left: var(--space-xs);
}
.margin-left-sm {
  margin-left: 0.75em;
  margin-left: var(--space-sm);
}
.margin-left-md {
  margin-left: 1.25em;
  margin-left: var(--space-md);
}
.margin-left-lg {
  margin-left: 2em;
  margin-left: var(--space-lg);
}
.margin-left-xl {
  margin-left: 3.25em;
  margin-left: var(--space-xl);
}
.margin-left-xxl {
  margin-left: 5.25em;
  margin-left: var(--space-xxl);
}
.margin-left-xxxl {
  margin-left: 8.5em;
  margin-left: var(--space-xxxl);
}
.margin-left-xxxxl {
  margin-left: 13.75em;
  margin-left: var(--space-xxxxl);
}
.margin-left-auto {
  margin-left: auto;
}
.margin-x-xxxxs {
  margin-left: 0.125em;
  margin-left: var(--space-xxxxs);
  margin-right: 0.125em;
  margin-right: var(--space-xxxxs);
}
.margin-x-xxxs {
  margin-left: 0.25em;
  margin-left: var(--space-xxxs);
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.margin-x-xxs {
  margin-left: 0.375em;
  margin-left: var(--space-xxs);
  margin-right: 0.375em;
  margin-right: var(--space-xxs);
}
.margin-x-xs {
  margin-left: 0.5em;
  margin-left: var(--space-xs);
  margin-right: 0.5em;
  margin-right: var(--space-xs);
}
.margin-x-sm {
  margin-left: 0.75em;
  margin-left: var(--space-sm);
  margin-right: 0.75em;
  margin-right: var(--space-sm);
}
.margin-x-md {
  margin-left: 1.25em;
  margin-left: var(--space-md);
  margin-right: 1.25em;
  margin-right: var(--space-md);
}
.margin-x-lg {
  margin-left: 2em;
  margin-left: var(--space-lg);
  margin-right: 2em;
  margin-right: var(--space-lg);
}
.margin-x-xl {
  margin-left: 3.25em;
  margin-left: var(--space-xl);
  margin-right: 3.25em;
  margin-right: var(--space-xl);
}
.margin-x-xxl {
  margin-left: 5.25em;
  margin-left: var(--space-xxl);
  margin-right: 5.25em;
  margin-right: var(--space-xxl);
}
.margin-x-xxxl {
  margin-left: 8.5em;
  margin-left: var(--space-xxxl);
  margin-right: 8.5em;
  margin-right: var(--space-xxxl);
}
.margin-x-xxxxl {
  margin-left: 13.75em;
  margin-left: var(--space-xxxxl);
  margin-right: 13.75em;
  margin-right: var(--space-xxxxl);
}
.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-y-xxxxs {
  margin-top: 0.125em;
  margin-top: var(--space-xxxxs);
  margin-bottom: 0.125em;
  margin-bottom: var(--space-xxxxs);
}
.margin-y-xxxs {
  margin-top: 0.25em;
  margin-top: var(--space-xxxs);
  margin-bottom: 0.25em;
  margin-bottom: var(--space-xxxs);
}
.margin-y-xxs {
  margin-top: 0.375em;
  margin-top: var(--space-xxs);
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.margin-y-xs {
  margin-top: 0.5em;
  margin-top: var(--space-xs);
  margin-bottom: 0.5em;
  margin-bottom: var(--space-xs);
}
.margin-y-sm {
  margin-top: 0.75em;
  margin-top: var(--space-sm);
  margin-bottom: 0.75em;
  margin-bottom: var(--space-sm);
}
.margin-y-md {
  margin-top: 1.25em;
  margin-top: var(--space-md);
  margin-bottom: 1.25em;
  margin-bottom: var(--space-md);
}
.margin-y-lg {
  margin-top: 2em;
  margin-top: var(--space-lg);
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
}
.margin-y-xl {
  margin-top: 3.25em;
  margin-top: var(--space-xl);
  margin-bottom: 3.25em;
  margin-bottom: var(--space-xl);
}
.margin-y-xxl {
  margin-top: 5.25em;
  margin-top: var(--space-xxl);
  margin-bottom: 5.25em;
  margin-bottom: var(--space-xxl);
}
.margin-y-xxxl {
  margin-top: 8.5em;
  margin-top: var(--space-xxxl);
  margin-bottom: 8.5em;
  margin-bottom: var(--space-xxxl);
}
.margin-y-xxxxl {
  margin-top: 13.75em;
  margin-top: var(--space-xxxxl);
  margin-bottom: 13.75em;
  margin-bottom: var(--space-xxxxl);
}
.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
@media not all and (min-width: 32rem) {
  .has-margin\@xs {
    margin: 0 !important;
  }
}
@media not all and (min-width: 48rem) {
  .has-margin\@sm {
    margin: 0 !important;
  }
}
@media not all and (min-width: 64rem) {
  .has-margin\@md {
    margin: 0 !important;
  }
}
@media not all and (min-width: 80rem) {
  .has-margin\@lg {
    margin: 0 !important;
  }
}
@media not all and (min-width: 90rem) {
  .has-margin\@xl {
    margin: 0 !important;
  }
}
.padding-md {
  padding: 1.25em;
  padding: var(--space-md);
}
.padding-xxxxs {
  padding: 0.125em;
  padding: var(--space-xxxxs);
}
.padding-xxxs {
  padding: 0.25em;
  padding: var(--space-xxxs);
}
.padding-xxs {
  padding: 0.375em;
  padding: var(--space-xxs);
}
.padding-xs {
  padding: 0.5em;
  padding: var(--space-xs);
}
.padding-sm {
  padding: 0.75em;
  padding: var(--space-sm);
}
.padding-lg {
  padding: 2em;
  padding: var(--space-lg);
}
.padding-xl {
  padding: 3.25em;
  padding: var(--space-xl);
}
.padding-xxl {
  padding: 5.25em;
  padding: var(--space-xxl);
}
.padding-xxxl {
  padding: 8.5em;
  padding: var(--space-xxxl);
}
.padding-xxxxl {
  padding: 13.75em;
  padding: var(--space-xxxxl);
}
.padding-component {
  padding: 1.25em;
  padding: var(--component-padding);
}
.padding-top-md {
  padding-top: 1.25em;
  padding-top: var(--space-md);
}
.padding-top-xxxxs {
  padding-top: 0.125em;
  padding-top: var(--space-xxxxs);
}
.padding-top-xxxs {
  padding-top: 0.25em;
  padding-top: var(--space-xxxs);
}
.padding-top-xxs {
  padding-top: 0.375em;
  padding-top: var(--space-xxs);
}
.padding-top-xs {
  padding-top: 0.5em;
  padding-top: var(--space-xs);
}
.padding-top-sm {
  padding-top: 0.75em;
  padding-top: var(--space-sm);
}
.padding-top-lg {
  padding-top: 2em;
  padding-top: var(--space-lg);
}
.padding-top-xl {
  padding-top: 3.25em;
  padding-top: var(--space-xl);
}
.padding-top-xxl {
  padding-top: 5.25em;
  padding-top: var(--space-xxl);
}
.padding-top-xxxl {
  padding-top: 8.5em;
  padding-top: var(--space-xxxl);
}
.padding-top-xxxxl {
  padding-top: 13.75em;
  padding-top: var(--space-xxxxl);
}
.padding-top-component {
  padding-top: 1.25em;
  padding-top: var(--component-padding);
}
.padding-bottom-md {
  padding-bottom: 1.25em;
  padding-bottom: var(--space-md);
}
.padding-bottom-xxxxs {
  padding-bottom: 0.125em;
  padding-bottom: var(--space-xxxxs);
}
.padding-bottom-xxxs {
  padding-bottom: 0.25em;
  padding-bottom: var(--space-xxxs);
}
.padding-bottom-xxs {
  padding-bottom: 0.375em;
  padding-bottom: var(--space-xxs);
}
.padding-bottom-xs {
  padding-bottom: 0.5em;
  padding-bottom: var(--space-xs);
}
.padding-bottom-sm {
  padding-bottom: 0.75em;
  padding-bottom: var(--space-sm);
}
.padding-bottom-lg {
  padding-bottom: 2em;
  padding-bottom: var(--space-lg);
}
.padding-bottom-xl {
  padding-bottom: 3.25em;
  padding-bottom: var(--space-xl);
}
.padding-bottom-xxl {
  padding-bottom: 5.25em;
  padding-bottom: var(--space-xxl);
}
.padding-bottom-xxxl {
  padding-bottom: 8.5em;
  padding-bottom: var(--space-xxxl);
}
.padding-bottom-xxxxl {
  padding-bottom: 13.75em;
  padding-bottom: var(--space-xxxxl);
}
.padding-bottom-component {
  padding-bottom: 1.25em;
  padding-bottom: var(--component-padding);
}
.padding-right-md {
  padding-right: 1.25em;
  padding-right: var(--space-md);
}
.padding-right-xxxxs {
  padding-right: 0.125em;
  padding-right: var(--space-xxxxs);
}
.padding-right-xxxs {
  padding-right: 0.25em;
  padding-right: var(--space-xxxs);
}
.padding-right-xxs {
  padding-right: 0.375em;
  padding-right: var(--space-xxs);
}
.padding-right-xs {
  padding-right: 0.5em;
  padding-right: var(--space-xs);
}
.padding-right-sm {
  padding-right: 0.75em;
  padding-right: var(--space-sm);
}
.padding-right-lg {
  padding-right: 2em;
  padding-right: var(--space-lg);
}
.padding-right-xl {
  padding-right: 3.25em;
  padding-right: var(--space-xl);
}
.padding-right-xxl {
  padding-right: 5.25em;
  padding-right: var(--space-xxl);
}
.padding-right-xxxl {
  padding-right: 8.5em;
  padding-right: var(--space-xxxl);
}
.padding-right-xxxxl {
  padding-right: 13.75em;
  padding-right: var(--space-xxxxl);
}
.padding-right-component {
  padding-right: 1.25em;
  padding-right: var(--component-padding);
}
.padding-left-md {
  padding-left: 1.25em;
  padding-left: var(--space-md);
}
.padding-left-xxxxs {
  padding-left: 0.125em;
  padding-left: var(--space-xxxxs);
}
.padding-left-xxxs {
  padding-left: 0.25em;
  padding-left: var(--space-xxxs);
}
.padding-left-xxs {
  padding-left: 0.375em;
  padding-left: var(--space-xxs);
}
.padding-left-xs {
  padding-left: 0.5em;
  padding-left: var(--space-xs);
}
.padding-left-sm {
  padding-left: 0.75em;
  padding-left: var(--space-sm);
}
.padding-left-lg {
  padding-left: 2em;
  padding-left: var(--space-lg);
}
.padding-left-xl {
  padding-left: 3.25em;
  padding-left: var(--space-xl);
}
.padding-left-xxl {
  padding-left: 5.25em;
  padding-left: var(--space-xxl);
}
.padding-left-xxxl {
  padding-left: 8.5em;
  padding-left: var(--space-xxxl);
}
.padding-left-xxxxl {
  padding-left: 13.75em;
  padding-left: var(--space-xxxxl);
}
.padding-left-component {
  padding-left: 1.25em;
  padding-left: var(--component-padding);
}
.padding-x-md {
  padding-left: 1.25em;
  padding-left: var(--space-md);
  padding-right: 1.25em;
  padding-right: var(--space-md);
}
.padding-x-xxxxs {
  padding-left: 0.125em;
  padding-left: var(--space-xxxxs);
  padding-right: 0.125em;
  padding-right: var(--space-xxxxs);
}
.padding-x-xxxs {
  padding-left: 0.25em;
  padding-left: var(--space-xxxs);
  padding-right: 0.25em;
  padding-right: var(--space-xxxs);
}
.padding-x-xxs {
  padding-left: 0.375em;
  padding-left: var(--space-xxs);
  padding-right: 0.375em;
  padding-right: var(--space-xxs);
}
.padding-x-xs {
  padding-left: 0.5em;
  padding-left: var(--space-xs);
  padding-right: 0.5em;
  padding-right: var(--space-xs);
}
.padding-x-sm {
  padding-left: 0.75em;
  padding-left: var(--space-sm);
  padding-right: 0.75em;
  padding-right: var(--space-sm);
}
.padding-x-lg {
  padding-left: 2em;
  padding-left: var(--space-lg);
  padding-right: 2em;
  padding-right: var(--space-lg);
}
.padding-x-xl {
  padding-left: 3.25em;
  padding-left: var(--space-xl);
  padding-right: 3.25em;
  padding-right: var(--space-xl);
}
.padding-x-xxl {
  padding-left: 5.25em;
  padding-left: var(--space-xxl);
  padding-right: 5.25em;
  padding-right: var(--space-xxl);
}
.padding-x-xxxl {
  padding-left: 8.5em;
  padding-left: var(--space-xxxl);
  padding-right: 8.5em;
  padding-right: var(--space-xxxl);
}
.padding-x-xxxxl {
  padding-left: 13.75em;
  padding-left: var(--space-xxxxl);
  padding-right: 13.75em;
  padding-right: var(--space-xxxxl);
}
.padding-x-component {
  padding-left: 1.25em;
  padding-left: var(--component-padding);
  padding-right: 1.25em;
  padding-right: var(--component-padding);
}
.padding-y-md {
  padding-top: 1.25em;
  padding-top: var(--space-md);
  padding-bottom: 1.25em;
  padding-bottom: var(--space-md);
}
.padding-y-xxxxs {
  padding-top: 0.125em;
  padding-top: var(--space-xxxxs);
  padding-bottom: 0.125em;
  padding-bottom: var(--space-xxxxs);
}
.padding-y-xxxs {
  padding-top: 0.25em;
  padding-top: var(--space-xxxs);
  padding-bottom: 0.25em;
  padding-bottom: var(--space-xxxs);
}
.padding-y-xxs {
  padding-top: 0.375em;
  padding-top: var(--space-xxs);
  padding-bottom: 0.375em;
  padding-bottom: var(--space-xxs);
}
.padding-y-xs {
  padding-top: 0.5em;
  padding-top: var(--space-xs);
  padding-bottom: 0.5em;
  padding-bottom: var(--space-xs);
}
.padding-y-sm {
  padding-top: 0.75em;
  padding-top: var(--space-sm);
  padding-bottom: 0.75em;
  padding-bottom: var(--space-sm);
}
.padding-y-lg {
  padding-top: 2em;
  padding-top: var(--space-lg);
  padding-bottom: 2em;
  padding-bottom: var(--space-lg);
}
.padding-y-xl {
  padding-top: 3.25em;
  padding-top: var(--space-xl);
  padding-bottom: 3.25em;
  padding-bottom: var(--space-xl);
}
.padding-y-xxl {
  padding-top: 5.25em;
  padding-top: var(--space-xxl);
  padding-bottom: 5.25em;
  padding-bottom: var(--space-xxl);
}
.padding-y-xxxl {
  padding-top: 8.5em;
  padding-top: var(--space-xxxl);
  padding-bottom: 8.5em;
  padding-bottom: var(--space-xxxl);
}
.padding-y-xxxxl {
  padding-top: 13.75em;
  padding-top: var(--space-xxxxl);
  padding-bottom: 13.75em;
  padding-bottom: var(--space-xxxxl);
}
.padding-y-component {
  padding-top: 1.25em;
  padding-top: var(--component-padding);
  padding-bottom: 1.25em;
  padding-bottom: var(--component-padding);
}
@media not all and (min-width: 32rem) {
  .has-padding\@xs {
    padding: 0 !important;
  }
}
@media not all and (min-width: 48rem) {
  .has-padding\@sm {
    padding: 0 !important;
  }
}
@media not all and (min-width: 64rem) {
  .has-padding\@md {
    padding: 0 !important;
  }
}
@media not all and (min-width: 80rem) {
  .has-padding\@lg {
    padding: 0 !important;
  }
}
@media not all and (min-width: 90rem) {
  .has-padding\@xl {
    padding: 0 !important;
  }
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
@media (min-width: 32rem) {
  .text-center\@xs {
    text-align: center;
  }
  .text-left\@xs {
    text-align: left;
  }
  .text-right\@xs {
    text-align: right;
  }
}
@media (min-width: 48rem) {
  .text-center\@sm {
    text-align: center;
  }
  .text-left\@sm {
    text-align: left;
  }
  .text-right\@sm {
    text-align: right;
  }
}
@media (min-width: 64rem) {
  .text-center\@md {
    text-align: center;
  }
  .text-left\@md {
    text-align: left;
  }
  .text-right\@md {
    text-align: right;
  }
}
@media (min-width: 80rem) {
  .text-center\@lg {
    text-align: center;
  }
  .text-left\@lg {
    text-align: left;
  }
  .text-right\@lg {
    text-align: right;
  }
}
@media (min-width: 90rem) {
  .text-center\@xl {
    text-align: center;
  }
  .text-left\@xl {
    text-align: left;
  }
  .text-right\@xl {
    text-align: right;
  }
}
.color-inherit {
  color: inherit;
}
.color-contrast-medium {
  color: hsl(240, 1%, 48%);
  color: var(--color-contrast-medium, #79797c);
}
.color-contrast-high {
  color: hsl(240, 4%, 20%);
  color: var(--color-contrast-high, #313135);
}
.color-contrast-higher {
  color: hsl(240, 8%, 12%);
  color: var(--color-contrast-higher, #1c1c21);
}
.color-primary {
  color: hsl(220, 90%, 56%);
  color: var(--color-primary, #2a6df4);
}
.color-accent {
  color: hsl(355, 90%, 61%);
  color: var(--color-accent, #f54251);
}
.color-success {
  color: hsl(94, 48%, 56%);
  color: var(--color-success, #88c559);
}
.color-warning {
  color: hsl(46, 100%, 61%);
  color: var(--color-warning, #ffd138);
}
.color-error {
  color: hsl(355, 90%, 61%);
  color: var(--color-error, #f54251);
}
.width-100\% {
  width: 100%;
}
.height-100\% {
  height: 100%;
}
.media-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}
.media-wrapper iframe,
.media-wrapper video,
.media-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-wrapper video,
.media-wrapper img {
  -o-object-fit: cover;
  object-fit: cover;
}
.media-wrapper--4\:3 {
  padding-bottom: 75%;
}
:root,
[data-theme="default"] {
  --color-primary-darker: hsl(220, 90%, 36%);
  --color-primary-darker-h: 220;
  --color-primary-darker-s: 90%;
  --color-primary-darker-l: 36%;
  --color-primary-dark: hsl(220, 90%, 46%);
  --color-primary-dark-h: 220;
  --color-primary-dark-s: 90%;
  --color-primary-dark-l: 46%;
  --color-primary: hsl(220, 90%, 56%);
  --color-primary-h: 220;
  --color-primary-s: 90%;
  --color-primary-l: 56%;
  --color-primary-light: hsl(220, 90%, 66%);
  --color-primary-light-h: 220;
  --color-primary-light-s: 90%;
  --color-primary-light-l: 66%;
  --color-primary-lighter: hsl(220, 90%, 76%);
  --color-primary-lighter-h: 220;
  --color-primary-lighter-s: 90%;
  --color-primary-lighter-l: 76%;
  --color-accent-darker: hsl(355, 90%, 41%);
  --color-accent-darker-h: 355;
  --color-accent-darker-s: 90%;
  --color-accent-darker-l: 41%;
  --color-accent-dark: hsl(355, 90%, 51%);
  --color-accent-dark-h: 355;
  --color-accent-dark-s: 90%;
  --color-accent-dark-l: 51%;
  --color-accent: hsl(355, 90%, 61%);
  --color-accent-h: 355;
  --color-accent-s: 90%;
  --color-accent-l: 61%;
  --color-accent-light: hsl(355, 90%, 71%);
  --color-accent-light-h: 355;
  --color-accent-light-s: 90%;
  --color-accent-light-l: 71%;
  --color-accent-lighter: hsl(355, 90%, 81%);
  --color-accent-lighter-h: 355;
  --color-accent-lighter-s: 90%;
  --color-accent-lighter-l: 81%;
  --color-black: hsl(240, 8%, 12%);
  --color-black-h: 240;
  --color-black-s: 8%;
  --color-black-l: 12%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-success-darker: hsl(94, 48%, 36%);
  --color-success-darker-h: 94;
  --color-success-darker-s: 48%;
  --color-success-darker-l: 36%;
  --color-success-dark: hsl(94, 48%, 46%);
  --color-success-dark-h: 94;
  --color-success-dark-s: 48%;
  --color-success-dark-l: 46%;
  --color-success: hsl(94, 48%, 56%);
  --color-success-h: 94;
  --color-success-s: 48%;
  --color-success-l: 56%;
  --color-success-light: hsl(94, 48%, 66%);
  --color-success-light-h: 94;
  --color-success-light-s: 48%;
  --color-success-light-l: 66%;
  --color-success-lighter: hsl(94, 48%, 76%);
  --color-success-lighter-h: 94;
  --color-success-lighter-s: 48%;
  --color-success-lighter-l: 76%;
  --color-error-darker: hsl(355, 90%, 41%);
  --color-error-darker-h: 355;
  --color-error-darker-s: 90%;
  --color-error-darker-l: 41%;
  --color-error-dark: hsl(355, 90%, 51%);
  --color-error-dark-h: 355;
  --color-error-dark-s: 90%;
  --color-error-dark-l: 51%;
  --color-error: hsl(355, 90%, 61%);
  --color-error-h: 355;
  --color-error-s: 90%;
  --color-error-l: 61%;
  --color-error-light: hsl(355, 90%, 71%);
  --color-error-light-h: 355;
  --color-error-light-s: 90%;
  --color-error-light-l: 71%;
  --color-error-lighter: hsl(355, 90%, 81%);
  --color-error-lighter-h: 355;
  --color-error-lighter-s: 90%;
  --color-error-lighter-l: 81%;
  --color-warning-darker: hsl(46, 100%, 41%);
  --color-warning-darker-h: 46;
  --color-warning-darker-s: 100%;
  --color-warning-darker-l: 41%;
  --color-warning-dark: hsl(46, 100%, 51%);
  --color-warning-dark-h: 46;
  --color-warning-dark-s: 100%;
  --color-warning-dark-l: 51%;
  --color-warning: hsl(46, 100%, 61%);
  --color-warning-h: 46;
  --color-warning-s: 100%;
  --color-warning-l: 61%;
  --color-warning-light: hsl(46, 100%, 71%);
  --color-warning-light-h: 46;
  --color-warning-light-s: 100%;
  --color-warning-light-l: 71%;
  --color-warning-lighter: hsl(46, 100%, 81%);
  --color-warning-lighter-h: 46;
  --color-warning-lighter-s: 100%;
  --color-warning-lighter-l: 81%;
  --color-bg: hsl(0, 0%, 100%);
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-contrast-lower: hsl(0, 0%, 95%);
  --color-contrast-lower-h: 0;
  --color-contrast-lower-s: 0%;
  --color-contrast-lower-l: 95%;
  --color-contrast-low: hsl(240, 1%, 83%);
  --color-contrast-low-h: 240;
  --color-contrast-low-s: 1%;
  --color-contrast-low-l: 83%;
  --color-contrast-medium: hsl(240, 1%, 48%);
  --color-contrast-medium-h: 240;
  --color-contrast-medium-s: 1%;
  --color-contrast-medium-l: 48%;
  --color-contrast-high: hsl(240, 4%, 20%);
  --color-contrast-high-h: 240;
  --color-contrast-high-s: 4%;
  --color-contrast-high-l: 20%;
  --color-contrast-higher: hsl(240, 8%, 12%);
  --color-contrast-higher-h: 240;
  --color-contrast-higher-s: 8%;
  --color-contrast-higher-l: 12%;
}
@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --space-unit: 1.25em;
    }
  }
}
:root {
  --radius: 0.25em;
}
:root {
  --font-primary: sans-serif;
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  --text-xs: calc(1em / var(--text-scale-ratio) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(
    var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 1;
}
@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --text-base-size: 1.25em;
      --text-scale-ratio: 1.25;
    }
  }
}
mark {
  background-color: hsla(355, 90%, 61%, 0.2);
  background-color: hsla(
    var(--color-accent-h),
    var(--color-accent-s),
    var(--color-accent-l),
    0.2
  );
  color: inherit;
}
.text-component {
  --line-height-multiplier: 1;
  --text-vspace-multiplier: 1;
}
.text-component blockquote {
  padding-left: 1em;
  border-left: 4px solid hsl(240, 1%, 83%);
  border-left: 4px solid var(--color-contrast-low);
}
.text-component hr {
  background: hsl(240, 1%, 83%);
  background: var(--color-contrast-low);
  height: 1px;
}
.text-component figcaption {
  font-size: 0.83333em;
  font-size: var(--text-sm);
  color: hsl(240, 1%, 48%);
  color: var(--color-contrast-medium);
}
.article.text-component {
  --line-height-multiplier: 1.13;
  --text-vspace-multiplier: 1.2;
}
:root {
  --btn-font-size: 1em;
  --btn-font-size-sm: calc(var(--btn-font-size) - 0.2em);
  --btn-font-size-md: calc(var(--btn-font-size) + 0.2em);
  --btn-font-size-lg: calc(var(--btn-font-size) + 0.4em);
  --btn-radius: 0.25em;
  --btn-padding-x: var(--space-sm);
  --btn-padding-y: var(--space-xs);
}
.btn {
  --color-shadow: hsla(240, 8%, 12%, 0.15);
  --color-shadow: hsla(
    var(--color-black-h),
    var(--color-black-s),
    var(--color-black-l),
    0.15
  );
  box-shadow: 0 4px 16px hsla(240, 8%, 12%, 0.15);
  box-shadow: 0 4px 16px
    hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15);
  cursor: pointer;
}
.btn--primary {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn--accent {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn--disabled {
  opacity: 0.6;
}
:root {
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.25em;
}
.form-control {
  border: 2px solid hsl(240, 1%, 83%);
  border: 2px solid var(--color-contrast-low);
}
.form-control:focus {
  outline: none;
  border-color: hsl(220, 90%, 56%);
  border-color: var(--color-primary);
  --color-shadow: hsla(220, 90%, 56%, 0.2);
  --color-shadow: hsla(
    var(--color-primary-h),
    var(--color-primary-s),
    var(--color-primary-l),
    0.2
  );
  box-shadow: undefined;
  box-shadow: 0 0 0 3px var(--color-shadow);
}
.form-control:focus:focus {
  box-shadow: 0 0 0 3px hsla(220, 90%, 56%, 0.2);
  box-shadow: 0 0 0 3px var(--color-shadow);
}
.form-control[aria-invalid="true"] {
  border-color: hsl(355, 90%, 61%);
  border-color: var(--color-error);
}
.form-control[aria-invalid="true"]:focus {
  --color-shadow: hsla(355, 90%, 61%, 0.2);
  --color-shadow: hsla(
    var(--color-error-h),
    var(--color-error-s),
    var(--color-error-l),
    0.2
  );
  box-shadow: undefined;
  box-shadow: 0 0 0 3px var(--color-shadow);
}
.form-control[aria-invalid="true"]:focus:focus {
  box-shadow: 0 0 0 3px hsla(355, 90%, 61%, 0.2);
  box-shadow: 0 0 0 3px var(--color-shadow);
}
.form-label {
  font-size: 0.83333em;
  font-size: var(--text-sm);
}
:root {
  --cd-color-1: hsl(218, 7%, 32%);
  --cd-color-1-h: 218;
  --cd-color-1-s: 7%;
  --cd-color-1-l: 32%;
  --cd-color-2-h: 127;
  --cd-color-2-s: 83%;
  --cd-color-2-l: 80%;
  --font-primary: "Jaldi", sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1), 0 16px 48px rgba(0, 0, 0, 0.1),
    0 24px 60px rgba(0, 0, 0, 0.1);
  box-shadow: var(--shadow-lg);
}
.cd-accordion--animated .cd-accordion__label::before {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.cd-accordion__sub {
  display: none;
  overflow: hidden;
}
.cd-accordion__sub--is-visible {
  display: block;
}
.cd-accordion__item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cd-accordion__input {
  position: absolute;
  opacity: 0;
}
.cd-accordion__label {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75em 1.25em;
  padding: var(--space-sm) var(--space-md);
  background: hsl(218, 7%, 32%);
  background: var(--cd-color-1);
  --color-shadow: lightness(hsl(218, 7%, 32%), 1.2);
  --color-shadow: lightness(var(--cd-color-1), 1.2);
  box-shadow: inset 0 -1px lightness(hsl(218, 7%, 32%), 1.2);
  box-shadow: inset 0 -1px var(--color-shadow);
  color: hsl(0, 0%, 100%);
  color: var(--color-white);
}
.cd-accordion__label span {
  -ms-flex-order: 3;
  order: 3;
}
.cd-accordion__label:hover {
  background: hsl(218, 7%, 35.2%);
  background: hsl(
    var(--cd-color-1-h),
    var(--cd-color-1-s),
    calc(var(--cd-color-1-l) * 1.1)
  );
}
.cd-accordion__label::after,
.cd-accordion__label--icon-folder::before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.cd-accordion__label--icon-folder::before {
  -ms-flex-order: 1;
  order: 1;
}
.cd-accordion__label::after {
  -ms-flex-order: 2;
  order: 2;
}
.cd-accordion__label--icon-folder::before {
  background-position: 0 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.cd-accordion__label--icon-folder::after {
  background-position: -16px 0;
}
.cd-accordion__label--icon-img::after {
  background-position: -48px 0;
}
.cd-accordion__input:checked + .cd-accordion__label::before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.cd-accordion__input:checked + .cd-accordion__label::after {
  background-position: -32px 0;
}
.cd-accordion__input:checked ~ .cd-accordion__sub {
  display: block;
}
.cd-accordion__sub--l1 .cd-accordion__label {
  background: hsl(218, 7%, 20.8%);
  background: hsl(
    var(--cd-color-1-h),
    var(--cd-color-1-s),
    calc(var(--cd-color-1-l) * 0.65)
  );
  --color-shadow: lightness(hsl(218, 7%, 32%), 0.85);
  --color-shadow: lightness(var(--cd-color-1), 0.85);
  box-shadow: inset 0 -1px lightness(hsl(218, 7%, 32%), 0.85);
  box-shadow: inset 0 -1px var(--color-shadow);
  padding-left: calc(1.25em + 16px);
  padding-left: calc(var(--space-md) + 16px);
}
.cd-accordion__sub--l1 .cd-accordion__label:hover {
  background: hsl(218, 7%, 24%);
  background: hsl(
    var(--cd-color-1-h),
    var(--cd-color-1-s),
    calc(var(--cd-color-1-l) * 0.75)
  );
}
.cd-accordion__item:last-child .cd-accordion__label {
  box-shadow: none;
}
.cd-accordion__sub--l2 .cd-accordion__label {
  padding-left: calc(1.5em + 32px);
  padding-left: calc(var(--space-md) + var(--space-xxxs) + 32px);
}
.cd-accordion__sub--l3 .cd-accordion__label {
  padding-left: calc(1.5em + 48px);
  padding-left: calc(var(--space-md) + var(--space-xxxs) + 48px);
}
