@media (max-width: 768px) {
    .authWrapCol1{
      display: none;
    }
 
  }
  .resetBtn {
    width: 100%;
    height: 45px;
    margin-top: 30px;
  }
  .resetBtn button {
    background: green;
    height: 100%;
    width: 100%;
    border: none;
    color: white;
  }