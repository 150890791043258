@import '../../../../resources';
.customer {
  padding: 100px 30px 50px 30px;
  .top {
    padding-bottom: 32px;
    svg {
      cursor: pointer;
    }
    h2 {
      cursor: pointer;
    }
  }
  .customer-wrap {
    width: 100%;
    .one {
      background-color: #fff;
      width: 30%;
      border-radius: 4px;
      padding: 26px 16px;

      .placed-card {
        border: 1px solid $main-color;
        border-radius: 4px;
        background-color: #edf0f8;
        padding: 16px;
        margin-bottom: 16px;
        width: 100%;
        p {
          color: #595f62;
          font-size: 12px;
          padding-bottom: 8px;
        }
        h2 {
          color: #0f2568;
          font-size: 16px;
        }
      }
      .details {
        .details-card {
          margin-bottom: 24px;
          width: 48%;
          p {
            color: #595f62;
            font-size: 12px;
            padding-bottom: 8px;
          }
          h3 {
            color: #0f2568;
            font-size: 14px;
          }
        }
        .details-card-long {
          margin-bottom: 24px;
          p {
            color: #595f62;
            font-size: 12px;
            padding-bottom: 8px;
          }
          h3 {
            color: #0f2568;
            font-size: 14px;
          }
          button {
            margin-top: 10px;
            background-color: #0f2568;
            color: #fff;
            border-radius: 4px;
            border: none;
            padding: 5px;
            font-size: 14px;
          }
        }
      }
    }
    .two {
      background-color: #fff;
      width: 68%;
      border-radius: 4px;
      padding: 26px 16px;
      .wrap-top {
        h4 {
          font-weight: 700;
          color: #0f2568;
          font-size: 16px;
        }
      }
      overflow-x: auto;
      table {
        width: 100%;
        border-top: 2px solid $main-color;
        border-radius: 4px;
        thead {
          tr {
            th {
              font-size: 16px;
              padding: 15px 10px;
              background-color: #fff;
              min-width: 100px;
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 14px;
              padding: 10px 10px;
              background-color: #fff;
              min-width: 100px;
              &.count {
                text-align: right;
              }
              a {
                text-decoration: none;
                button {
                  font-size: 14px;
                  border: none;
                  padding: 10px 30px;
                  background: #446ce4;
                  border: 1px solid #446ce4;
                  border-radius: 21px;
                  color: #fff;
                  display: flex;
                }
              }
            }
            &:nth-child(odd) {
              td {
                background-color: #f1f6ff;
              }
            }
          }
        }
      }
    }
  }
}
.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #00000091;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-body {
    width: 100%;
    max-width: 500px;
    background-color: #fff;
    border-radius: 4px;
    text-align: center;
    h1 {
      font-weight: 900;
      font-size: 25px;
      padding-bottom: 10px;
    }
    .buttons {
      justify-content: center;
      gap: 10px;
      margin-top: 20px;
    }
    button {
      padding: 12px 23px;
      background: #f1f5fd;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 19px;
      margin-bottom: 20px;
      &.proceed {
        background-color: #138808;
        border: 1px solid #138808;
        color: #fff;
      }
    }
  }
}
