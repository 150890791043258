.react-slideshow-container .default-nav:first-of-type {
  display: none;
}
.react-slideshow-container .default-nav:last-of-type {
  display: none;
}
@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.testimonial6 {
  font-family: 'Montserrat', sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.testimonial6 h1,
.testimonial6 h2,
.testimonial6 h3,
.testimonial6 h4,
.testimonial6 h5,
.testimonial6 h6 {
  color: #3e4555;
}

.testimonial6 .font-weight-medium {
  font-weight: 500;
}

.testimonial6 h5 {
  line-height: 22px;
  font-size: 18px;
}

.testimonial6 .subtitle {
  color: #8d97ad;
  line-height: 24px;
  font-size: 16px;
}

.testimonial6 .testi6 {
  border-right: 1px solid rgba(120, 130, 140, 0.13);
}

.testimonial6 .testi6 .nav-link {
  border-radius: 0px;
  margin: 8px -2px 8px 0;
}

.testimonial6 .testi6 .nav-link img {
  width: 70px;
  opacity: 0.5;
}

.testimonial6 .testi6 .nav-link.active {
  background: transparent;
  color: #8d97ad;
  border-right: 3px solid rgba(2, 5, 161, 0.91);
}

.testimonial6 .testi6 .nav-link.active img {
  opacity: 1;
}

.testimonial6 .btn-danger {
  background: #ff4d7e !important;
  border: 1px solid #ff4d7e !important;
}

.testimonial6 .btn-md {
  padding: 18px 0px;
  width: 60px;
  height: 60px;
  font-size: 20px;
}

@media (max-width: 767px) {
  .testimonial6 .testi6 .nav-link {
    margin: 0px 0px -2px 0;
    padding: 10px;
  }
  .testimonial6 .testi6 {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    border-right: 0px solid rgba(120, 130, 140, 0.13);
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    margin-bottom: 40px;
  }
  .testimonial6 .testi6 .nav-link img {
    width: 40px;
  }
  .testimonial6 .testi6 .nav-link.active {
    border-right: 0px solid rgba(2, 5, 161, 0.91);
    border-bottom: 3px solid rgba(2, 5, 161, 0.91);
  }
}
.awssld__content {
  background-color: #fff;
}
.awssld__content > img,
.awssld__content > video {
  /* width: fit-content; */
}
.awssld__timer {
  background-color: rgba(2, 5, 161, 0.91);
}
.home-testimonial {
  background-color: #2a2a72;
  background-image: linear-gradient(315deg, #090990 0%, #4169e2 74%);
  height: 450px;
}
.home-testimonial-bottom {
  background-color: #f8f8f8;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 20px;
  margin-bottom: 0px;
  position: relative;
  height: 130px;
  top: 190px;
  display: inline;
}
.home-testimonial h3 {
  color: var(--orange);
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.home-testimonial h2 {
  color: white;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 30px;
}
.testimonial-inner {
  position: relative;
  top: -174px;
}
.testimonial-pos {
  position: relative;
  top: 24px;
}
.testimonial-inner .tour-desc {
  border-radius: 5px;
  padding: 40px;
  height: 300px;
}
.color-grey-3 {
  font-family: 'Montserrat', Sans-serif;
  font-size: 14px;
  color: #6c83a2;
}
.testimonial-inner img.tm-people {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  max-width: none;
}
.link-name {
  font-family: 'Montserrat', Sans-serif;
  font-size: 14px;
  color: #6c83a2;
}
.link-position {
  font-family: 'Montserrat', Sans-serif;
  font-size: 12px;
  color: #6c83a2;
}
