@import '../../resources';
.topbar {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
  z-index: 10;
  .logo {
    padding: 0 10px;
    width: 17%;
    display: flex;
    align-items: center;
    .toggle {
      display: none;
      @include mobile-1024px {
        display: block;
      }
      svg {
        font-size: 30px;
        cursor: pointer;
      }
    }
    img {
      width: 150px;
      margin-left: 15px;
    }
  }
  .rest {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 83%;
    padding-right: 50px;
    @include mobile-500px {
      padding-right: 20px;
    }
    .page_name {
      h1 {
        font-size: 20px;
        @include mobile-1024px {
          display: none;
        }
      }
    }
    .may_account {
      position: relative;
      .account_head {
        display: flex;
        cursor: pointer;
        align-items: center;
        .user {
          margin-right: 10px;
        }
        p {
          font-size: 16px;
          margin-right: 7px;
          @include mobile-500px {
            display: none;
          }
        }
      }
      .account_body {
        background: #fff;
        padding: 20px 10px;
        position: absolute;
        width: 100%;
        display: none;
      }
    }
  }
}
