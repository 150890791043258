.step {
    position: relative;
    min-height: 1em;
    color: gray;
    margin-top: 50px;
  }
  .title {
    line-height: 1.5em;
    font-weight: bold;
  }
  .caption {
    font-size: 0.8em;
  }
  .step + .step {
    margin-top: 1.5em;
    margin-top: 50px;

  }
  .step > div:first-child {
    position: static;
    height: 0;
  }
  .step > div:not(:first-child) {
    margin-left: 1.5em;
    padding-left: 1em;
  }
  /* Circle */
.circle {
    background: gray;
    position: relative;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    border-radius: 100%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 0 3px #fff;
  }
  /* Vertical Line */
.circle:after {
    content: ' ';
    position: absolute;
    display: block;
    top: 1px;
    right: 50%;
    bottom: 1px;
    left: 50%;
    height: 100%;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: -1;
  }
  .step:last-child .circle:after {
    display: none
  }
  .step.step-active {
    color: #4285f4
  }
  .step.step-active .circle {
    background-color: #4285f4;
  }
  @media (max-width: 575px) {
    .leftwhere{
      display: none;
    }
    .where-right-main{
      padding: 20px 20px 20px 20px;
    }
    .cancelBtn{
      /* margin-top: -50px; */
      position: absolute;
      margin-left: -30px;
      padding-left: 10%;
    }
    .btn-danger{
      width: 100%;
    }
  }