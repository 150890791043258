.refer-friend-arrow {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.refer-arrow {
  margin-left: 50px;
}
.refer-cancel {
  margin-right: 50px;
}

.refer-friend-state-cont {
  text-align: center;
}
.refer-friend-state-cont h2 {
  margin-top: 10px;
  font-weight: 1000;
  font-size: 43px;

  color: #4169e2;
}
.refer-friend-note {
  font-weight: 800;
  font-size: 15px;
  line-height: 18px;

  letter-spacing: 0.2px;

  color: #323232;
  margin-top: 10px;
  margin-bottom: 25px;
}

.refer-friend-input p {
  font-size: 13px;
  line-height: 24px;

  color: rgba(36, 66, 46, 0.75);
  text-align: left;
  margin-left: 482px;
  font-weight: bold;
}
.refer-friend-input input {
  padding: 10px 20px;
  width: 400px;
  border: 1px solid #929292;
  outline: none;
  margin-top: 5px;
}

.refer-button {
  margin-top: 25px;
  padding: 10px 20px;
  width: 400px;
  background-color: #000000;
  color: #ffffff;
  outline: none;
  border: none;
  font-size: 14px;
}
