@import '../../../../resources';
.home_page {
  padding: 0 50px;
  padding-bottom: 100px;
  margin-bottom: 50px;
  padding-top: 100px;

  @include mobile-800px {
    padding: 0 20px;
    padding-top: 100px;
  }
  .home_button {
    margin-bottom: 19px;
    button {
      padding: 12px 23px;
      background: #f1f5fd;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 14px;
      margin-right: 19px;
      margin-bottom: 20px;
    }
  }
  .home_tag {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    align-items: center;
    h1 {
      font-size: 28px;
    }
    .home_select {
      @include mobile-600px {
        width: 100%;
        padding-top: 10px;
        text-align: right;
      }
      select {
        background: #ffffff;
        border: 0.5px solid #dedede;
        border-radius: 6px;
        padding: 10px 20px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    button {
      background: #446ce4;
      border-radius: 4px;
      border: 1px solid #446ce4;
      font-size: 20px;
      padding: 7px 20px;
      color: #fff;
    }
  }
  .home_card {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    flex-wrap: wrap;
    .card {
      display: flex;
      align-items: center;
      width: 32%;
      background-color: #fff;
      // padding: 25px;
      border-radius: 10px;
      margin-bottom: 10px;
      @include mobile-800px {
        width: 49%;
      }
      @include mobile-600px {
        width: 100%;
      }
      .left {
        margin-right: 13px;
        margin-left: 25px;
        .round {
          width: 40px;
          height: 40px;
          background-color: #ffe5c6;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 20px;
            color: #b37027;
          }
        }
        &.dif {
          .round {
            background-color: #dbffc6;
            svg {
              color: #7aa360;
            }
          }
        }
      }
      .right {
        padding: 25px 25px 25px 0;
        p {
          letter-spacing: 0.02em;
          font-size: 14px;
          padding-bottom: 5px;
        }
        h2 {
          font-size: 24px;
          letter-spacing: 0.02em;
          word-break: break-all;
        }
      }
    }
  }
  .home_tables {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;
    .buttons {
      padding-bottom: 30px;
      button {
        background: none;
        border-radius: 4px;
        border: 1px solid #446ce4;
        font-weight: 600;
        font-size: 20px;
        padding: 10px 25px;
        color: #1c1c1c;
        &:nth-child(2) {
          margin-left: 20px;
        }
        &.active {
          background: #446ce4;
          border: none;
        }
      }
    }
    .left {
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      padding: 25px 30px;
      overflow-x: auto;
      min-height: 40vh;
      @include mobile-800px {
        width: 100%;
        margin-bottom: 30px;
      }
      .left_top {
        padding-bottom: 20px;
        h2 {
          font-size: 20px;
        }
      }
      table {
        width: 100%;
        thead {
          tr {
            th {
              font-size: 16px;
              padding: 20px 10px;
              text-align: left;
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #f5f9ff;
            }
            td {
              font-size: 14px;
              padding: 10px 10px;
            }
          }
        }
      }
    }
  }
}
.deposit {
  padding: 0 50px;
  padding-bottom: 100px;
  margin-bottom: 150px;
  padding-top: 100px;

  @include mobile-800px {
    padding: 0 20px;
    padding-top: 100px;
  }
  .home_tag {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    h1 {
      font-size: 28px;
    }
    .home_select {
      @include mobile-600px {
        width: 100%;
        padding-top: 10px;
        text-align: right;
      }
      select {
        background: #ffffff;
        border: 0.5px solid #dedede;
        border-radius: 6px;
        padding: 10px 20px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
  .deposit-wrap {
    background: #ffffff;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.04);
    border-radius: 25.6px;
    padding: 25px;
    form,
    .form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .cards {
        width: 45%;
        padding-bottom: 30px;
        &.ong {
          width: 100%;
        }
        @include mobile-800px {
          width: 100%;
        }
        p {
          font-size: 20px;
          padding-bottom: 10px;
        }
        select,
        input {
          width: 100%;
          height: 50px;
          border: 1px solid #afafaf;
          font-size: 18px;
          color: #222;
          padding: 0 5px;
        }
        .inputy {
          height: 50px;
          border: 1px solid #afafaf;
          display: flex;
          align-items: center;
          p {
            color: #222;
            font-size: 18px;
            padding: 0 10px;
          }
        }
        button {
          background: #fc733c;
          border-radius: 4px;
          border: none;
          font-weight: 600;
          font-size: 16px;
          padding: 10px 20px;
          color: #1c1c1c;
          margin-top: 20px;
        }
      }
      .long {
        width: 100%;
        display: flex;
        align-items: center;
        @include mobile-800px {
          flex-wrap: wrap;
        }
        .left {
          width: 40%;
          @include mobile-800px {
            width: 100%;
          }
          img {
            width: 100%;
          }
        }
        .right {
          width: 58%;
          @include mobile-800px {
            width: 100%;
            text-align: center;
          }
          .one {
            font-weight: 600;
            font-size: 16px;
            line-height: 15px;
            color: #4a4a4a;
            width: 80%;
            padding-bottom: 20px;
            span {
              text-transform: uppercase;
            }
            @include mobile-800px {
              width: 100%;
            }
          }
          .two {
            font-weight: 600;
            font-size: 16px;
            line-height: 15px;
            color: #4a4a4a;

            padding-bottom: 10px;
          }
          h2 {
            color: #222;
            font-weight: 700;
            padding-bottom: 20px;
          }
          .inputtype {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 57.6px;
            background: #f0f3ff;
            border-radius: 12.8px;
            padding: 0 20px;
            @include mobile-500px {
              overflow-x: auto;
              padding: 10px;
              justify-content: center;
              flex-wrap: wrap;
            }
            p {
              color: #4a4a4a;
              font-size: 16px;
              word-break: break-all;
              @include mobile-500px {
                padding-bottom: 10px;
              }
            }

            .button {
              background-color: #516ae4;
              color: #fff;
              border: none;
              padding: 10px;
              border-radius: 6px;
              font-size: 12px;
              max-width: 30px;
            }
          }
        }
      }
      .button {
        width: 100%;
        text-align: center;
        p {
          font-size: 12px;
          padding-bottom: 10px;
        }
        button {
          background: #fc733c;
          border-radius: 4px;
          border: none;
          font-weight: 600;
          font-size: 20px;
          padding: 10px 20px;
          color: #1c1c1c;
        }
      }
    }
  }
}
.loadings {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  img {
    width: 150px;
  }
}
